export const content = {
    title: 'Познакомьтесь с нашей командой',
    subtitle: 'Мы творим будущее',
    text: 'Наши мастера обладают прекрасным стилем и высокими профессиональными качествами.<br/><br/>За годы мы выработали свой особенный стиль и рады приобщать клиентов к прекрасному. У нас не просто студия, это сообщество.',
    menus: [{
        id: 'team',
        text: 'Команда'
    }, {
        id: 'advisors',
        text: ''
    }, ]
}

export const FooterBoxes = [
    {
        title: 'Связаться с нами',
        text: 'Напишите нам и наши менеджеры обязательно с Вами свяжуться',
        button: {
            text: 'Контакты',
            href: '/contacts',
            id: 'operator',
            type: 'simple'
        }
    },
    {
        title: 'Присоединяйтесь в нашу группу Telegram',
        text: 'Все последние новости и события нашего агентство!',
        button: {
            text: 'Перейти',
            href: 'https://t.me/simbios_agency',
            id: 'career',
            type: 'simple'
        }
    }
]


export const anchors = [{
    text: 'Команда',
    anchor: 'team'
},]